import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import PostItem from '../components/PostItem';
import TitlePage from '../components/TitlePage';
import LocalizedLink from '../components/LocalizedLink';
import Image from "gatsby-image";
import useTranslations from '../components/useTranslations';
import ms777Image from "../images/ms777.jpg";
import * as S from '../components/ListWrapper/styled';




const Index = ({ data: {  } }) => {
  // useTranslations is aware of the global context (and therefore also "locale")
  // so it'll automatically give back the right translations
  const {
    title,
    subline,
    category,
    latestPosts,
    allPosts,
  } = useTranslations();



  return (
    <div className="homepage">
      <SEO title="主頁" />
      <TitlePage text={title} />
      <p>{subline}</p>
      <br/>
      <img src={ms777Image} alt="MS777" />
    </div>
  );
};



export default Index;

export const query = graphql`
  query Index($locale: String!, $dateFormat: String!, ) {
    allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: {regex: "/(news)\/.*\\.md$/"}
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 2
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            category
            background
            image
            date(formatString: $dateFormat)

          }
          timeToRead
          fields {
            locale
            slug
          }
        }
      }
    }
  }
`;
